<template>
  <PageContent class="resultats-recherche">
    <template #header-right>
      <SelectClassic
        v-if="orderingOptions[currentTabKey]"
        v-model="currentOrdering[currentTabKey]"
        :options="orderingOptions[currentTabKey]"
        :use-placeholder="false"
        :search-bar="false"
        :disabled="resultCountTabs[currentTabKey] === 0"
        @select="changeOrdering"
      >
        <template #before-option>
          {{ $t('filtre.trier-par') }}
        </template>
      </SelectClassic>
      <ButtonGroup :selected-id="viewMode">
        <ButtonClassic
          id="grid"
          v-matomo-log-click="['viewMode', 'grid', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'grid'"
        >
          <template #left-icon>
            <UilTable />
          </template>
        </ButtonClassic>
        <ButtonClassic
          id="list"
          v-matomo-log-click="['viewMode', 'list', ]"
          variant="solid"
          icon="left"
          @click="viewMode = 'list'"
        >
          <template #left-icon>
            <IconListView />
          </template>
        </ButtonClassic>
      </ButtonGroup>
      <ButtonClassic
        v-if="currentTabKey !== 'fournitures' && currentTabObj"
        :label="$t('filtre.filtres')"
        color="primary"
        :variant="showFilterSidebar ? 'solid' : 'ghost'"
        :class="{ active: showFilterSidebar, }"
        icon="right"
        :disabled="!currentTabObj.results?.length || !allFilters[currentTabKey]?.length"
        @click="showFilterSidebar = !showFilterSidebar"
      >
        <template #right-icon>
          <UilFilter v-if="!showFilterSidebar" />
          <UilTimes v-else />
        </template>
      </ButtonClassic>
    </template>

    <template #action-bar>
      <div id="search_header">
        <div id="search_header_content">
          <TabsGroup
            :tabs="tabs"
            @change="changeTab($event)"
          />
        </div>
      </div>
    </template>

    <template #aside-content>
      <FilterSidebar
        v-if="showFilterSidebar && currentTabKey !== 'fournitures' && allFilters[currentTabKey].length"
        :title="$t('recherche.affiner-votre-recherche')"
        :possible-filters="allFilters[currentTabKey]"
        :active-filters="activeFilters[currentTabKey]"
        @update="searchResults(false)"
      />
    </template>

    <template #content>
      <div v-if="loadingTabs[currentTabKey] || !currentTabObj">
        <InfiniteLoader />
      </div>
      <div v-else-if="currentTabObj?.results?.length">
        <!-- On génère le contenu de l'onglet courant -->
        <div class="grid-zone">
          <ProductItem
            v-for="(elem, index) in currentTabObj.results"
            :key="elem.id"
            v-model="currentTabObj.results[index]"
            :view="viewMode"
            small
          />
        </div>
        <div
          v-if="resultCountTabs[currentTabKey] > pageSize"
          class="pagination-container"
        >
          <Pagination
            :key="`currentTab-${currentTabKey}`"
            :value="parseInt(currentPages[currentTabKey]) || 1"
            :total-rows="resultCountTabs[currentTabKey] < 10000 ? resultCountTabs[currentTabKey] : 10000 - pageSize"
            :per-page="pageSize"
            @change="changePage(currentTabKey, $event)"
          />
        </div>
      </div>
      <ErrorPage
        v-else
        :title="$t('recherche.aucun-resultat')"
        :content="$t('recherche.aucun-produit-correspondant')"
        :zero-search-results="$route.query.only && !$route.query.advanced ? true : false"
        @retry-search="retrySearch()"
      >
        <template #custom-icon>
          <img
            :src="require('@lde/core_lde_vue/dist/assets/media/illus/illus_zero_resultat.svg')"
            :alt="$t('recherche.illustration-zero-resultat')"
          />
        </template>
      </ErrorPage>
    </template>
  </PageContent>
</template>

<script>
import {
  ButtonGroup,
  ButtonClassic,
  SelectClassic,
  InfiniteLoader,
  Pagination,
  PageContent,
  TabsGroup,
} from "@lde/core_lde_vue";

import ProductItem from "@/components/products/ProductItem.vue";
import FilterSidebar from "@/components/search/filters/FilterSidebar.vue";
import ErrorPage from "@/components/layout/ErrorPage.vue";

import ListProducts from "@/mixins/mixinListProducts";

import Api from "@/modules/axios";

import IconListView from "@/components/icons/IconListView.vue";
import { UilTable, UilFilter, UilTimes } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Vue des résultats de la recherche.
 */
export default {
  name: "ResultatsRecherche",
  components: {
    PageContent,
    TabsGroup,
    ProductItem,
    Pagination,
    SelectClassic,
    FilterSidebar,
    ButtonGroup,
    ButtonClassic,
    ErrorPage,
    InfiniteLoader,
    IconListView,
    UilTable,
    UilFilter,
    UilTimes,
  },
  mixins: [ListProducts],
  /**
   * Avant d'arriver sur la route, on récupère la route précédente.
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data() {
    return {
      results: [],
      currentTab: 0,
      currentPages: {},
      pageSize: 24,
      prevRoute: null,
      requestsFinished: false,
      loadingTabs: {},
      resultCountTabs: {},
      currentOrdering: {},
      oldOrdering: {},
      showFilterSidebar: false,
      allFilters: {},
    };
  },
  computed: {

    ...mapGetters(["searchKey", "hasPerm"]),
    tabs() {
      const onlyTab = this.$route.query.only || "";
      let tabs = [];

      if (
        this.hasPerm("view_articlepapier")
        && (!onlyTab || onlyTab === "papiers")
      ) {
        tabs.push({
          key: "papiers",
          title: this.$t("menu.livres-et-manuels-papier"),
        });
      }

      if (
        this.hasPerm("view_manuelnumerique")
        && (!onlyTab || onlyTab === "numeriques")
      ) {
        tabs.push({
          key: "numeriques",
          title: this.$t("menu.ressources-numeriques"),
        });
      }

      if (
        this.hasPerm("view_fourniture")
        && (!onlyTab || onlyTab === "fournitures")
      ) {
        tabs.push({
          key: "fournitures",
          title: this.$t("menu.fournitures-et-papeterie"),
        });
      }

      tabs = tabs.map((tab) => ({
        ...tab,
        active: this.currentTabKey === tab.key,
        results: this.results[tab.key],
        notification: this.resultCountTabs[tab.key] || undefined,
        maxNotifications: this.resultCountTabs[tab.key] === 10000,
        routerLinkObj: {
          query: {
            ...this.$route.query,
            tab: tab.key,
            page: this.currentPages[tab.key],
          },
        },
      }));

      return tabs;
    },
    tabKeys() {
      return this.tabs.map((tab) => tab.key);
    },
    currentTabKey() {
      return this.$route.query.tab || this.$route.query.only;
    },
    currentTabObj() {
      return this.tabs.find(({ key }) => key === this.$route.query.tab || this.$route.query.only);
    },
    activeFilters() {
      const query = this.$route.query;
      const activeFilters = {};
      this.tabKeys.forEach((tab) => {
        activeFilters[tab] = Object.entries(query)
          .filter(([key]) => key.includes(tab) && !key.includes("adv_") && query[key] !== null)
          .map(([key, value]) => ({ slug: key, options: value.toString().split(",").map(Number) }));
      });
      return activeFilters;
    },
    orderingOptions() {
      const res = {};
      if (this.tabs.find((tab) => tab.key === "papiers")) {
        res.papiers = [
          {
            label: this.$t("recherche.pertinence"),
            value: "pertinence",
            ordering: "pertinence",
          },
          {
            label: this.$t("recherche.publication-croissante"),
            value: "publication_croissante",
            ordering: "date_publication",
          },
          {
            label: this.$t("recherche.publication-decroissante"),
            value: "publication_decroissante",
            ordering: "-date_publication",
          },
          // Il n'y a qu'une offre donc on peut faire ça pour l'instant
          {
            label: this.$t("recherche.prix-croissant"),
            value: "prix_croissant",
            ordering: "prix",
          },
          {
            label: this.$t("recherche.prix-decroissant"),
            value: "prix_decroissant",
            ordering: "-prix",
          },
        ];
      }

      if (this.tabs.find((tab) => tab.key === "fournitures")) {
        res.fournitures = [
          {
            label: this.$t("recherche.pertinence"),
            value: "pertinence",
            ordering: "pertinence",
          },
          // Il n'y a qu'une offre donc on peut faire ça pour l'instant
          {
            label: this.$t("recherche.prix-croissant"),
            value: "prix_croissant",
            ordering: "prix",
          },
          {
            label: this.$t("recherche.prix-decroissant"),
            value: "prix_decroissant",
            ordering: "-prix",
          },
          {
            label: this.$t("recherche.marque-a-z"),
            value: "marque",
            ordering: "marque",
          },
          {
            label: this.$t("recherche.marque-z-a"),
            value: "-marque",
            ordering: "-marque",
          },
        ];
      }

      if (this.tabs.find((tab) => tab.key === "numeriques")) {
        res.numeriques = [
          {
            label: this.$t("recherche.pertinence"),
            value: "pertinence",
            ordering: "pertinence",
          },
          {
            label: this.$t("recherche.editeur-a-z"),
            value: "editeur",
            ordering: "editeur",
          },
          {
            label: this.$t("recherche.editeur-z-a"),
            value: "-editeur",
            ordering: "-editeur",
          },
          {
            label: this.$t("recherche.titre-a-z"),
            value: "titre",
            ordering: "libelle",
          },
          {
            label: this.$t("recherche.titre-z-a"),
            value: "-titre",
            ordering: "-libelle",
          },
        ];
      }

      return res;
    },
  },
  watch: {
    "$route.query.search": {
      handler() {
        this.resetPages();
      },
    },
    searchKey() {
      this.resetPages();
      this.searchResults();
    },
  },
  mounted() {
    this.resetPages();
    this.$set(this.currentPages, this.currentTabKey, this.$route.query.page);
    this.tabKeys.forEach((key) => {
      this.currentOrdering[key] = this.orderingOptions[this.currentTabKey][0];
    });
    this.searchResults();
  },
  methods: {
    /**
     * Réinitialise les onglets.
     */
    resetPages() {
      this.tabs.forEach(({ key }) => {
        this.$set(this.currentPages, key, 1);
        this.$set(this.loadingTabs, key, true);
      });

      if (!this.$route.query.tab && !this.$route.query.only) {
        this.$router.push({ query: { ...this.$route.query, tab: this.tabs[0].key } });
      }
    },
    /**
     * Change d'onglet et met en place les filtres et l'ordering.
     */
    changeTab() {
      // L'ordering est lié à chaque tab, on doit donc le mettre à jour quand on change. On met "pertinence" par défaut.
      const queryOrdering = this.$route.query.ordering || "pertinence";
      if (queryOrdering !== this.currentOrdering[this.currentTabKey]?.ordering) {
        // On change la queryString si ça ne correspond pas avec l'ordering du tab actuel
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ordering: this.currentOrdering[this.currentTabKey].ordering,
          },
        });
      }
    },
    /**
     * Change la page actuelle.
     */
    changePage(key, value) {
      this.$set(this.currentPages, key, value);
      this.$router.push({ query: { ...this.$route.query, page: value } });
      this.searchResults(false);
    },
    /**
     * Change l'ordre de tri du tab actuel.
     * @param {Object} option Option sélectionnée.
     */
    changeOrdering(option) {
      if (this.oldOrdering[this.currentTabKey] !== option) {
        this.oldOrdering[this.currentTabKey] = this.currentOrdering[this.currentTabKey];
        this.currentOrdering[this.currentTabKey] = option;
        const tab = this.$route.query.tab || this.$route.query.only;
        this.$set(this.currentPages, tab, 1);
        // On change la queryString
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            ordering: option.ordering,
          },
        });
        // On charge les nouveaux résultats si le tri a changé
        this.searchResults(false);
      }
    },
    /**
     * Effectue une recherche selon l'onglet actif.
     * @param {Object} tab Onglet qu'il faut mettre à jour.
     * @param {Object} params Paramètres de recherche pour la requête.
     * @returns {Promise} Offres selon catalogue.
     */
    searchTab(tab, params, advanced = false) {
      let url = "/recherche/";
      let advArgs = [];
      let filterArgs = [];

      if (advanced) {
        // Gestion du querystring en cas de recherche avancée
        url = "/recherche/advanced_search";
        const query = this.$route.query;
        advArgs = Object.entries(query)
          .filter(([key]) => key.includes(this.currentTabKey) && key.includes("adv_"))
          .map(([key, value]) => {
            let newKey = key.replace(`${tab}_`, "");
            if (key.includes("adv_marque") || key.includes("adv_coloris") || key.includes("adv_editeur")) {
              newKey = `${newKey}.libelle`;
            }
            return [newKey, value];
          });

        this.$matomo.trackSiteSearch(JSON.stringify(advArgs), null, null);
        this.$nextTick(() => {
          this.$set(this, "currentTab", this.tabKeys.indexOf(tab));
        });
      }

      if (this.activeFilters[tab]) {
        filterArgs = this.activeFilters[tab].map((param) => {
          const newOptions = param.options.join(",");
          return [param.slug.replace(`${tab}_`, ""), newOptions];
        });
      }

      // Si le tri n'est pas renseigné pour ce tab, pertience par défaut.
      const ordering = this.currentOrdering[tab]?.ordering || "pertinence";

      return Api().get(url, {
        params: {
          ...params,
          ...Object.fromEntries(advArgs),
          ...Object.fromEntries(filterArgs),
          ordering,
          tab,
        },
      })
        .then(({ data }) => {
          if (data.filtres) {
            this.$set(this.allFilters, tab, data.filtres);
          }
          Object.entries(data.results).forEach(([k, value]) => {
            this.$set(this.resultCountTabs, k, value.count);
            this.$set(this.results, k, value.results);
            this.$set(this.loadingTabs, k, false);
          });
          return data;
        });
    },
    /**
     * Effectue une recherche classique ou avancée selon l'url.
     * @returns {Object} Résultats de la recherche.
     */
    async searchResults(allTabs = true) {
      this.showFilterSidebar = false;
      const tab = this.currentTabKey;
      const onlyTab = this.$route.query.only;
      const advanced = this.$route.query.advanced || this.$route.query.quicksearch;

      const pages = [];
      if (tab) {
        pages.push([`page_${tab}`, this.currentPages[tab] || 1]);
      }

      const params = {
        search: this.$route.query.search,
        page_size: this.pageSize,
        ...Object.fromEntries(pages),
      };

      if (!advanced) {
        this.$matomo.trackSiteSearch(params.search, null, null);
      }
      this.requestsFinished = false;

      if (allTabs && !onlyTab) {
        Promise.allSettled(this.tabKeys.map((t) => {
          this.$set(this.resultCountTabs, t, null);
          this.$set(this.loadingTabs, t, true);
          return this.searchTab(t, params);
        }))
          .then((res) => {
            res.forEach((resp, respIndex) => {
              if (resp.status === "rejected") {
                this.$set(this.resultCountTabs, this.tabKeys[respIndex], 0);
                this.$set(this.loadingTabs, this.tabKeys[respIndex], false);
              }
            });
            this.requestsFinished = true;
          });
      } else {
        // On remet à zéro le compteur de résultats s'il n'y a qu'un seul tab.
        if (this.tabs.length === 1) {
          this.resultCountTabs = {};
        }

        this.$set(this.resultCountTabs, null);
        this.$set(this.loadingTabs, tab, true);
        this.searchTab(tab, params, advanced).then(() => { this.requestsFinished = true; });
      }
    },
    getListItemLink(tab) {
      let link = "";

      if (tab === "listes") {
        link = "listes_devis_listes_item";
      } else if (tab === "devis") {
        link = "listes_devis_devis_item";
      } else if (tab === "commandes") {
        link = "commandes_factures_commandes_item";
      } else if (tab === "factures") {
        link = "commandes_factures_factures_item";
      }

      return link;
    },
    getListItemText(tab) {
      let text = "";

      if (tab === "listes") {
        text = this.$t("recherche.voir-la-liste");
      } else if (tab === "devis") {
        text = this.$t("recherche.voir-le-devis");
      } else if (tab === "commandes") {
        text = this.$t("recherche.voir-la-commande");
      } else if (tab === "factures") {
        text = this.$t("recherche.voir-la-facture");
      }

      return text;
    },
    retrySearch() {
      const term = this.$route.query.search;
      this.$router.replace({ query: { search: term } });
      this.$store.commit("addSearchKey");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_resultats_recherche.scss";
@use "@/assets/styles/views/_list_products.scss";
</style>
